import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CSectTitle03,
  CInfinitySlider03,
  COverflowTable,
  CSimpleCard,
  CTable,
  CBtnList,
  CInlineLinkList,
  LVenue,
  CFixedImg,
  LBanquetContact,
} from '../../../components/_index';
// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: (
              <>
                中宴会場 <br className="u_sp" />
                パラッツオ
              </>
            ),
            sub: <>PALAZZO</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/palazzo/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/palazzo/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <div className="u_pt50 u_mbLarge">
        <LWrap>
          <CInfinitySlider03
            data={[
              {
                img: {
                  src: '/assets/images/banquet/palazzo/img_banquet.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/banquet/palazzo/img_banquet02.png',
                  alt: '',
                },
              },
            ]}
          />
          <p className="u_mbMedium">
            レストラン&バンケット「パラッツオ」では、四季折々の素材を厳選したロイヤルパークホテル自慢のお料理を眺望の美しい会場で、着席スタイルや立食スタイルなど、さまざまなスタイルでご堪能いただけます。
          </p>
          <COverflowTable>
            <table className="c_fixedTable">
              <thead>
                <tr>
                  <th rowSpan={2}>スタイル</th>
                  <th colSpan={4}>ご人数</th>
                  <th rowSpan={2}>広さ(m²)</th>
                  <th rowSpan={2}>天井高</th>
                </tr>
                <tr>
                  <th>正餐</th>
                  <th>立食</th>
                  <th>スクール</th>
                  <th>シアター</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>全室</th>
                  <td>90</td>
                  <td>100</td>
                  <td>100</td>
                  <td>190</td>
                  <td>200</td>
                  <td>3</td>
                </tr>
              </tbody>
            </table>
          </COverflowTable>
        </LWrap>
      </div>
      <section className="u_mbLarge">
        <LWrap>
          <CSectTitle03 title="レイアウト例" />
          <CSimpleCard
            data={[
              {
                img: {
                  src: '/assets/images/banquet/palazzo/img_example.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/banquet/palazzo/img_example02.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/banquet/palazzo/img_example03.png',
                  alt: '',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section>
        <LWrap>
          <CSectTitle03 title="主要設備" />
          <CTable
            data={[
              {
                title: '音響',
                content: (
                  <>
                    <ul className="c_circleList">
                      <li>オーディオミキサー5ch</li>
                      <li>CDプレーヤー</li>
                      <li>スピーカー</li>
                      <li>マイクロフォン</li>
                      <li>ワイヤレスマイクロフォン</li>
                    </ul>
                  </>
                ),
              },
              {
                title: '照明',
                content: (
                  <>
                    <ul className="c_circleList">
                      <li>照明制御盤</li>
                    </ul>
                  </>
                ),
              },
            ]}
          />
          <CBtnList
            data={[
              {
                label: 'CO₂ゼロ MICE®のご案内',
                size: 'large',
                link: {
                  href: '/banquet/co2zero_mice/',
                },
                color: 'borderBlack',
              },
            ]}
          />
          <CInlineLinkList
            exClass="u_mt30"
            data={[
              {
                label: 'Web会議システム',
                link: {
                  href: '/banquet/on-line_meeting/',
                  blank: false,
                },
                //icon: 'document',
              },
            ]}
          />
        </LWrap>
      </section>
      <LVenue />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宴会・会議',
              path: '/banquet/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
